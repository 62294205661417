import React from 'react'
import {Box} from '@mui/material'
import loadingImage from '../../assets/Images/loading.gif'
const LoadingPage = () => {
  return (
    <Box sx={styles.loadingPageContainer}>
      <img src={loadingImage} alt="logo" style={styles.image} />
    </Box>
  )
}

const styles={
    loadingPageContainer:{
       width:'100%',
      height:'100vh',
      display:'flex',
      justifyContent:"center",
      alignItems:'center'
    },
    image:{
        width:'120px',
        height:'120px',
        borderRadius:'100%',
    }
}
export default LoadingPage