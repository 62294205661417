import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
    const {isTeacherLoggedIn}=useSelector(state=>state.teacher)
    console.log(isTeacherLoggedIn);
  return (
    isTeacherLoggedIn?<Outlet/>:<Navigate to={'/teacher_login'}/>
  )
}

export default PrivateRoute