import { unique_id } from "../utils/id_generator";

export const  questions=[
    {
    questionType:'Short Answer',    
    query:'',
    id:unique_id(),
    answers:[ 
    {label:"A",answer:''},
    {label:"B",answer:''},
    {label:"C",answer:''},
    {label:"D",answer:''}],
    answer:''
  }
]

const answers=[ 
    {label:"A",answer:''},
    {label:"B",answer:''},
    {label:"C",answer:''},
    {label:"D",answer:''}
  ]

export const setInitialAndAddQuestion=(questionType)=>{
  const data={
    QuestionType:questionType,    
    query:'',
    id:unique_id(),
    answer:'',
    correctAnswer:'',
  }
  if(questionType==='Short Answer'||"") {
    return [{...data,answers:[]}]
  }
  else if(questionType==="Multiple choice") {
    
    return [{...data,answers:answers}]
  }
}