import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { profileReducer } from "../page/personal_profile/redux/profileSlice";
import { colorReducer } from "../page/personal_profile/redux/colorSlice";
import { teacherReducer} from "./features/userSlice";
import { noteReducer } from "./features/noteSlice";
import { examReducer } from "./features/examSlice";
import { courseReducer } from "./features/courseSlice";
import { calenderReducer } from "./features/calenderSlice";


const persistConfig = {
  key: "persistCollection",
  storage,
};

const reducer = combineReducers({
    color:colorReducer,
    profile:profileReducer,
    teacher:teacherReducer,
    note:noteReducer,
    exam:examReducer,
    course:courseReducer,
    calender:calenderReducer


});


const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
