import { createSlice } from "@reduxjs/toolkit";


const courseSlice=createSlice({
    name:'course',
    initialState:{
        isCourseModalVisible:false
    },
    reducers:{
      setCourseModalVisible:(state,action)=>{
        state.isCourseModalVisible=!state.isCourseModalVisible
      }
    }
})

export const {setCourseModalVisible}=courseSlice.actions
export const courseReducer=courseSlice.reducer