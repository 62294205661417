import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basic_url } from "../../utils/api";
import { noteData } from "../../constants/note_Initial_data";

export const getTeacherNote=createAsyncThunk('getTeacherNote',async()=>{

})


export const createNote=createAsyncThunk('createNote',async({newNoteData,toast})=>{
    try {
            await axios.post(`${basic_url}/teacher/newNote`,newNoteData)
            toast.success('Your note successfully created')
    } catch (error) {
        console.log(error)
    }
})

export const getNotes=createAsyncThunk('getNotes',async({teacher_id})=>{
    try {
       const response= await axios.get(`${basic_url}/teacher/note/${teacher_id}`)
       return response.data
    } catch (error) {
        console.log(error)
    }
})


export const updateNote=createAsyncThunk('updateNote',async({id,noteData,toast})=>{
    try {
       const response= await axios.put(`${basic_url}/teacher/update_note/${id}`,noteData)
       toast.success('Your note successfully updated')
       return response.data
    } catch (error) {
        console.log(error)
    }
})

export const deleteNote=createAsyncThunk('deleteNote',async({id,toast})=>{
    try {
       const response= await axios.delete(`${basic_url}/teacher/delete_note/${id}`)
       toast.success('Your note successfully deleted')
       return response.data
    } catch (error) {
        console.log(error)
    }
})

export const createSharedNote=createAsyncThunk('createSharedNote',async({sharedData,toast})=>{
        try {
            const response=await axios.post(`${basic_url}/share_note`,sharedData)
            toast.success('your note successfully Shared')
            return response.data
        } catch (error) {
         toast.error(error?.response?.data?.message)             
        }
})


const noteSlice=createSlice({
    name:'note',
    initialState:{
        isNoteModalVisible:false,
        noteData:noteData,
        teacherNotes:[],
        isNoteLoading:false,
        isNoteDeleted:false,
        isNoteEditing:false,
        isNoteCreated:false,
        isNoteEdited:false
    },
    reducers:{
        setNoteModalVisible:(state,action)=>{
            state.isNoteModalVisible=!state.isNoteModalVisible
        },
        setNoteData:(state,action)=>{
            return{
                ...state,
                noteData:{
                     ...state.noteData,
                     ...action.payload 
                }
            }
           
        },
         restNoteData:(state,action)=>{
            state.noteData=noteData
            state.isNoteEditing=false
       },
        setNoteColor:(state,action)=>{
            return{
                ...state,
                noteData:{
                    ...state.noteData,
                    bgColor:action.payload
                }
            }
        },
        setNoteEditingBoolean:(state,action)=>{
            state.isNoteEditing=!state.isNoteEditing
             state.isNoteModalVisible=!state.isNoteModalVisible
        },
        setNoteEditingData:(state,action)=>{
            state.noteData=action.payload
        }
    },
    extraReducers:{
        [getNotes.pending]:(state,action)=>{
            state.isNoteLoading=true
        },
        [getNotes.fulfilled]:(state,action)=>{
            state.isNoteLoading=false
            state.teacherNotes=action.payload
        },
        [getNotes.rejected]:(state,action)=>{
            state.isNoteLoading=false
        },
        [createNote.pending]:(state,action)=>{
            state.isNoteCreated=false
        },
        [createNote.fulfilled]:(state,action)=>{
            state.isNoteCreated=true
        },
        [createNote.rejected]:(state,action)=>{
            state.isNoteCreated=false
        },
        [updateNote.pending]:(state,action)=>{
            state.isNoteEdited=false
        },
        [updateNote.fulfilled]:(state,action)=>{
            state.isNoteEdited=true
        },
        [updateNote.rejected]:(state,action)=>{
            state.isNoteEdited=false
        },
        [deleteNote.pending]:(state,action)=>{
            state.isNoteDeleted=false
        },
        [deleteNote.fulfilled]:(state,action)=>{
            state.isNoteDeleted=true
        },
        [deleteNote.rejected]:(state,action)=>{
            state.isNoteDeleted=false
        },
    }
})
export const {
setNoteModalVisible,
setNoteColor,
setNoteData,
restNoteData,
setNoteEditingData,
setNoteEditingBoolean
}=noteSlice.actions
export const noteReducer=noteSlice.reducer