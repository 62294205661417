export const initialVal={
    isTeacherLoggedIn: false,
    teacherData: [],
    personalInfo: [],
    sideBarName: "/",
    sideNavName: "",
    cardNavName: "",
    leavingPage: false,
    isTeacherSignedUp: false,
    isTeacherLogging: false,
    isExamStarted: false,
    verifyData:{},
    isUserVerifying:false,
    isPasswordResting:false,
    isSideBarVisible:false,
    studentsList:[],
    internalNavIndex:0,
    teacherOnTelegram:[]
}