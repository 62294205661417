
import { Suspense } from 'react';
import './App.css';
import MainRoute from './route/MainRoute';
import { BrowserRouter as Router } from 'react-router-dom';
import LoadingPage from './shared/components/LoadingPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App=()=>{
  return (
    <div className="App">
      <ToastContainer style={{zIndex:100000000}}/>
      <Suspense fallback={<LoadingPage/>}>
        <Router>
        <MainRoute/>
        </Router>
      </Suspense>
   
    </div>
  );
}

export default App;
