import React, { lazy } from 'react'
import {Route,Routes} from 'react-router-dom'
import ProfileHomePage from '../page/personal_profile/pages/ProfileHomePage'
import PrivateRoute from './PrivateRoute'

const Dashboard=lazy(()=>import('../page/personal_profile/pages/Dashboard'))
const MyExamList=lazy(()=>import('../page/personal_profile/pages/MyExamList'))
const MyCourse=lazy(()=>import('../page/personal_profile/pages/MyCourse'))
const MyNote=lazy(()=>import('../page/personal_profile/pages/MyNote'))
const Students=lazy(()=>import('../page/personal_profile/pages/Students'))
const Setting=lazy(()=>import('../page/personal_profile/pages/Setting'))
const Wallet=lazy(()=>import('../page/personal_profile/pages/Wallet'))
const Calender=lazy(()=>import('../page/personal_profile/pages/Calender'))
const LoginPage=lazy(()=>import('../page/auth/pages/LoginPage'))
const VerifyPhone=lazy(()=>import('../page/auth/pages/VerifyPhone'))
const ForgetPassword=lazy(()=>import('../page/auth/pages/ForgetPassword'))
const SignUp=lazy(()=>import('../page/auth/pages/SignUp'))
const PageIsNotFound=lazy(()=>import('../shared/components/PageIsNotFound'))

const MainRoute = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute/>}>
        <Route path='/' element={<ProfileHomePage/>}>
            <Route path='' element={<Dashboard/>}/>
            <Route path='myExam' element={<MyExamList/>}/>
            <Route path='courses' element={<MyCourse/>}/>
            <Route path='setting' element={<Setting/>}/>
            <Route path='wallet' element={<Wallet/>}/>
            <Route path='note' element={<MyNote/>}/>
            <Route path='note' element={<MyNote/>}/>
            <Route path='Students' element={<Students/>}/>
            <Route path='calender' element={<Calender/>}/>
        </Route>
        </Route>
        <Route path='/teacher_login' element={<LoginPage/>}/>
        <Route path='/teacher_signUp' element={<SignUp/>}/> 
        <Route path='/verify' element={<VerifyPhone/>}/>
        <Route path='/rest_password' element={<ForgetPassword/>}/> 
        <Route path='/*' element={<PageIsNotFound/>}/> 
    </Routes>
  )
}

export default MainRoute