import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { basic_url } from "../../utils/api";
import { initialVal } from "../../constants/initial_values";



export const signUp = createAsyncThunk(
  "signUp",
  async ({ teacherData, toast, navigate }) => {
    try {
      const response = await axios.post(`${basic_url}/teacher/signUp`, teacherData);
      toast.success("your account created");
      navigate("/teacher_login");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);


export const signIn = createAsyncThunk(
  "login",
  async ({teacherData,toast,navigate }) => {
    try {
      const response = await axios.post(`${basic_url}/teacher/signIn`, teacherData);
      toast.success(`Welcome ${response.data.firstName}`);
      navigate("/");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const restPassword = createAsyncThunk(
  "restPassword",
  async ({ teacherData, id, navigate, toast,from }) => {
    try {
      const response = await axios.put(
        `${basic_url}/teacher/new_password/${id}`,
        teacherData
      );
      toast.success("your password is rested");
      if(from!=="") navigate("/teacher_login");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async ({userData,id,toast}) => {
    try {
      const response = await axios.put(`${basic_url}/teacher/update_password/${id}`,userData);
      toast.success("your password is updated successfully");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);


export const sendToEmail = createAsyncThunk(
  "Teacher/sendToEmail",
  async ({ TeacherData, navigate, toast }) => {
    console.log(TeacherData);
    try {
      const response = await axios.post(`${basic_url}/teacher/send_email`, TeacherData);
      toast.success("email confirmed");
      navigate(`/rest_password/${response.data}`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }
);

export const getPersonalInfo = createAsyncThunk(
  "getPersonalInfo",
  async ({ id }) => {
    try {
      const response = await axios.get(`${basic_url}/teacher/get_personal_info/${id}`);
      return response.data;
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  }
);

export const updatePersonalInfo = createAsyncThunk(
  "updatePersonalInfo",
  async ({ TeacherData, toast }) => {
    try {
      const response = await axios.put(
        `${basic_url}/update_personal_info`,
        TeacherData
      );
      toast.success("your personal info is updated");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "verifyUser",
  async ({userData,toast,navigate}) => {
    try {
      const response = await axios.post(`${basic_url}/teacher/verify_user`,userData);
     
       if(response.data.verified){
         toast.success("your phone number is verified");
         navigate('/rest_password')
         return response.data
       };
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);


export const getUserByTelegramId=createAsyncThunk('getUserByTelegramId',async({telegramId})=>{
  try {
      const response= await axios.post(`${basic_url}/teacher/get_teacher_by_telegram_id?id=${telegramId}`)
      console.log(response.data);
      
      return response.data
  } catch (error) {
     console.log(error)
  }
})

const teacherSlice = createSlice({
  name: "teacher",
  initialState:initialVal,
  reducers: {
    logOut: (state, action) => {
      state.isTeacherLoggedIn = false;
      state.personalInfo=[]
      state.sideNavName='/'
    
    },
    setSideBar: (state, action) => {
      state.sideBarName = action.payload;
    },
    setNavBar: (state, action) => {
      state.sideNavName = action.payload;
    },
    setCardNav: (state, action) => {
      state.cardNavName = action.payload;
    },
    setExamStart: (state, action) => {
      state.isExamStarted = action.payload;
    },
    setLeavingPage: (state, action) => {
      state.leavingPage = action.payload;
    },
    setIsSideBarVisible: (state, action) => {
      state.isSideBarVisible =!state.isSideBarVisible;
    },
    setInternalNavIndex:(state,action)=>{
      state.internalNavIndex=action.payload
    }
  },
  extraReducers: {
    [signIn.pending]: (state, action) => {
      state.isTeacherLoggedIn = false;
      state.isTeacherLogging = true;
    },
    [signIn.fulfilled]: (state, action) => {
        state.personalInfo=action.payload
        state.isTeacherLoggedIn = true;
        state.isTeacherLogging = false;
    },
    [signIn.rejected]: (state, action) => {
     
      state.isTeacherLogging = false;
    },

    [signUp.pending]: (state, action) => {
      state.isTeacherLoggedIn = false;
      state.isTeacherSignedUp = true;
    },
    [signUp.fulfilled]: (state, action) => {
      state.isTeacherLoggedIn = true;
      state.isTeacherSignedUp = false;
    },

    [signUp.rejected]: (state, action) => {
      state.isTeacherLoggedIn = false;
      state.isTeacherSignedUp = false;
    },

    [sendToEmail.pending]: (state, action) => {
      state.isTeacherLogging = true;
    },
    [sendToEmail.fulfilled]: (state, action) => {
      state.isTeacherLogging = false;
    },

    [sendToEmail.rejected]: (state, action) => {
      state.isTeacherLogging = false;
    },

    [restPassword.pending]: (state, action) => {
      state.isTeacherLogging = true;
    },
    [restPassword.fulfilled]: (state, action) => {
      state.isTeacherLogging = false;
    },

    [restPassword.rejected]: (state, action) => {
      state.isTeacherLogging = false;
    },

    [getPersonalInfo.pending]: (state, action) => {
      state.personalInfo = [];
    },
    [getPersonalInfo.fulfilled]: (state, action) => {
      state.personalInfo = action.payload;
      state.isTeacherLoggedIn = true;
    },
    [getPersonalInfo.rejected]: (state, action) => {
      state.personalInfo = [];
    },
     [verifyUser.pending]: (state, action) => {
      state.verifyData = {};
      state.isUserVerifying=true
    },
    [verifyUser.fulfilled]: (state, action) => {
      state.verifyData = action.payload;
      state.isUserVerifying=false
    },
    [verifyUser.rejected]: (state, action) => {
      state.verifyData = action.payload?.error;
      state.isUserVerifying=false
    },
     [restPassword.pending]: (state, action) => {
      state.isPasswordResting=true
    },
    [restPassword.fulfilled]: (state, action) => {
      state.isPasswordResting=false
    },
    [restPassword.rejected]: (state, action) => {
      state.isPasswordResting=false
    },


     [getUserByTelegramId.pending]: (state, action) => {
      state.isLoading=true
    },
    [getUserByTelegramId.fulfilled]: (state, action) => {
      state.isLoading=false
      state.teacherOnTelegram=action.payload
    },
    [getUserByTelegramId.rejected]: (state, action) => {
      state.isLoading=false
    },
  },
});

export const {
  logOut,
  setSideBar,
  setCardNav,
  setExamStart,
  setLeavingPage,
  setNavBar,
  setIsSideBarVisible,
  setInternalNavIndex
} = teacherSlice.actions;

export const teacherReducer = teacherSlice.reducer;
