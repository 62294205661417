import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { basic_url } from "../../utils/api";
import { setInitialAndAddQuestion } from "../../constants/exam_intial_states";


export const createExam=createAsyncThunk('createExam',async({examData,toast})=>{
    try {
          await axios.post(`${basic_url}/teacher/create_teacher_exam`,examData)
          toast.success('Your question is created')
    } catch (error) {
        console.log(error)
    }
})

export const getTeacherExams=createAsyncThunk('getTeacherExams',async({teacher_id})=>{
    try {
          const response=await axios.get(`${basic_url}/teacher/get_teacher_exam/${teacher_id}`)
          return response.data
    } catch (error) {
        console.log(error)
    }
})

export const updateExams=createAsyncThunk('updateExams',async({id,examData,toast})=>{
    try {
          const response=await axios.put(`${basic_url}/teacher/update_teacher_exam/${id}`,examData)
          toast.success('your exam successfully updated')
          return response.data
    } catch (error) {
        console.log(error)
    }
})

export const deleteTeacherExams=createAsyncThunk('deleteTeacherExams',async({id,toast})=>{
    try {
          const response=await axios.delete(`${basic_url}/teacher/delete_teacher_exam/${id}`)
          toast.success('your question successfully deleted')
          return response.data
    } catch (error) {
        console.log(error)
    }
})


const examSlice=createSlice({
    name:'exam',
    initialState:{
        isExamModalVisible:false,
        isExamPreViewIsVisible:false,
        questions:[],
        teacherExams:[],
        examPreViewData:[],
        isExamCreating:false,
        isExamEditing:false,
        questionType:'Multiple choice',
        isBtnDisabled:true

    },
    reducers:{
     setExamModalVisible:(state,action)=>{
        state.isExamModalVisible=!state.isExamModalVisible
     },
     setExamPreViewModalVisible:(state,action)=>{
        state.isExamPreViewIsVisible=!state.isExamPreViewIsVisible
     },
    setExamPreViewData:(state,action)=>{
        state.examPreViewData=action.payload
     },
     setQuestion:(state,action)=>{
         if(state.isExamEditing){
            state.questions=[...state.questions.map(data=>{
                return {...data,query:[...data.query.map((data)=>{ 
          if(data.id===action.payload.id){
         return {...data,query:action.payload.value}
         }else return data
      })]}
            })]
        }else{
           state.questions=[...state.questions?.map((data)=>{ 
        if(data.id===action.payload.id){
         return {...data,query:action.payload.value}
        }else return data
      })]
        }
     
     },
     setMultipleQuestionAnswers:(state,action)=>{
          if(state.isExamEditing){
            state.questions=[...state.questions.map(data=>{
                return {...data,
                query:[...data.query.map(data=>{ 
             if(data.id===action.payload.id){
            return {...data,answers:data?.answers?.map(answerData=>
           {  
            if(answerData.label===action.payload.label) 
          {  
           return {...answerData,answer:action.payload.value}
          }else return answerData
         }
        )}  
        }else return data
      })]}
            })]
        }else{
           state.questions=[...state.questions?.map(data=>{ 
        if(data.id===action.payload.id){
          return {...data,answers:data?.answers?.map(answerData=>
          {  
            if(answerData.label===action.payload.label) 
          {  
           return {...answerData,answer:action.payload.value}
          }else return answerData
         }
        )}  
        }else return data
      })]
        }
       
   },
     addQuestion:(state,action)=>{
        if(state.isExamEditing){
            state.questions=[...state.questions.map(data=>{
                return {...data,query:[...data.query,...setInitialAndAddQuestion(action.payload)]}
            })]
        }else{
           state.questions=[...state.questions, ...setInitialAndAddQuestion(action.payload)] 
        }
     },
     deleteQuestion:(state,action)=>{
        if(state.isExamEditing){
            state.questions=[...state.questions.map(data=>{
                return {...data,query:[...data.query.filter((data)=>data.id!==action.payload.id)]}
            })]
        }else{
            state.questions=[...state.questions?.filter((data)=>data.id!==action.payload.id)]
        }
      
     },
     setQuestionType:(state,action)=>{
       state.questionType=action.payload
     },
     setCorrectAnswer:(state,action)=>{
          if(state.isExamEditing){
            state.questions=[...state.questions.map(data=>{
                return {...data,
                query:[...data.query.map((queryData)=>{ 
               if(queryData.id===action.payload.id){
               return {...queryData,answer:action.payload.value}
           }else return queryData
        })]}
            })]
        }else{
         state.questions=[...state.questions?.map((data)=>{ 
           if(data.id===action.payload.id){
             return {...data,answer:action.payload.value}
           }else return data
        })]
        }
       
     },
     emptyQuestion:(state,action)=>{
        state.questions=[]
     },
     setExamEditing:(state,action)=>{
        state.isExamEditing=action.payload
     },
     setQuestionData:(state,action)=>{
        state.questions=[action.payload]
     },
      setQuestionSubject:(state,action)=>{
       state.questions=[...state.questions?.map((data)=>{ 
         return {...data,Subject:action.payload}
      })]
     },
      setQuestionTime:(state,action)=>{
       state.questions=[...state.questions?.map((data)=>{ 
         return {...data,time:action.payload}
       
      })]
     },
     setExamTitle:(state,action)=>{
           state.questions=[...state.questions?.map((data)=>{ 
           return {...data,title:{...data.title,
            text:action.payload,
        }}
     
      })]
        
     },
     setFontStyle:(state,action)=>{
        if(!state.questions[0].title?.style?.isBtnClicked){
             state.questions=[...state.questions?.map((data)=>{ 
           return {...data,title:{...data.title,
            style:{
             ...data.title.style,   
            fontWeight:'bold',
            isBtnClicked:!state.questions[0]?.title?.style?.isBtnClicked
        }
        }}
     
      })]
        }else{
               state.questions=[...state.questions?.map((data)=>{ 
           return {...data,title:{...data.title,
            style:{
             ...data.title.style,   
            fontWeight:'',
            isBtnClicked:!state.questions[0]?.title?.style?.isBtnClicked
        }
        }}
     
      })]  
        }
     },
      setAlignStyle:(state,action)=>{
        state.questions=[...state.questions?.map((data)=>{ 
           return {...data,title:{...data.title,
            style:{
             ...data.title.style,   
            alignItems:action.payload
        }
        }}
     
      })]
        
     },
     setFontSize:(state,action)=>{
      
            state.questions=[...state.questions?.map((data)=>{ 
           return {...data,title:{...data.title,
            style:{
             ...data.title.style,   
            fontSize:action.payload
        }
        }}
     
      })]
        
     }
    },
    extraReducers:{
        [getTeacherExams.pending]:(state,action)=>{
            state.teacherExams=[]
        },
        [getTeacherExams.fulfilled]:(state,action)=>{
            state.teacherExams=action.payload
        },
        [getTeacherExams.rejected]:(state,action)=>{
            state.teacherExams=[]
        },
        [createExam.pending]:(state,action)=>{
            state.isExamCreating=true
        },
        [createExam.fulfilled]:(state,action)=>{
            state.isExamCreating=false
        },
        [createExam.rejected]:(state,action)=>{
            state.isExamCreating=true
        }
    }
})

export const {
setExamModalVisible,
addQuestion,
setQuestionType,
setQuestion,
setMultipleQuestionAnswers,
setCorrectAnswer,
deleteQuestion,
setExamPreViewModalVisible,
setExamPreViewData,
emptyQuestion,
setExamEditing,
setQuestionData,
setQuestionSubject,
setQuestionTime,
setExamTitle,
setFontStyle,
setAlignStyle,
setFontSize
}=examSlice.actions

export const examReducer=examSlice.reducer